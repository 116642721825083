import React from 'react';

function Footer() {
  return (
    <footer className='l-footer'>
      2024 © Wedding-invitation
    </footer>
  )
}

export default Footer;